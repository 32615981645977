/* eslint-disable max-len */
export const numberDigits = (number, fixedPoint = 1) => {
  if (!number) return { number, digit: '' };

  switch (true) {
    case (number / 1e3 >= 1 && number / 1e3 < 1000) || (number / 1e3 <= -1 && number / 1e3 > -1000):
      return { number: Number((number / 1e3).toFixed(fixedPoint)), digit: 'тыс' };
    case (number / 1e6 >= 1 && number / 1e6 < 1000) || (number / 1e6 <= -1 && number / 1e6 > -1000):
      return { number: Number((number / 1e6).toFixed(fixedPoint)), digit: 'млн' };
    /* case number / 1e9 >= 1 && number / 1e9 < 1000 || number / 1e9 <= -1 && number / 1e9 > -1000:
        return { number: Number((number / 1e9).toFixed(fixedPoint)), digit: intl.formatMessage({ id: 'NumberShort_Billion' }) };
    case number / 1e12 >= 1 && number / 1e12 < 1000 || number / 1e12 <= -1 && number / 1e12 > -1000:
        return { number: Number((number / 1e12).toFixed(fixedPoint)), digit: intl.formatMessage({ id: 'NumberShort_Trillion' }) };
    case number / 1e15 >= 1 && number / 1e15 < 1000 || number / 1e15 <= -1 && number / 1e15 > -1000:
        return { number: Number((number / 1e15).toFixed(fixedPoint)), digit: intl.formatMessage({ id: 'NumberShort_Quadrillion' }) }; */
    default:
      return { number: Number(number.toFixed(fixedPoint)), digit: '' };
  }
};

export const limitGeoPrev = (number) => {
  if (!number) return { number, digit: '' };

  if (number / 1e3 >= 1 && number / 1e3 < 10) {
    return { number: Number((number / 1e3).toFixed(1)), digit: 'k' };
  } else return { number: Number(number), digit: '' };
};

export const limitGeoLast = (number) => {
  if (!number) return { number, digit: '' };

  if (number / 1e3 >= 10 && number / 1e3 < 100) {
    return { number: Number(number.toFixed(0)), digit: '' };
  } else if (number / 1e3 >= 100) {
    return { number: Number((number / 1e3).toFixed(0)), digit: 'k' };
  } else return { number: Number(number), digit: '' };
};

export const limitGdpByActLast = (number) => {
  if (!number) return { number, digit: '' };

  if (number / 1e3 >= 1 && number / 1e3 < 10) {
    return { number: Number(number.toFixed(0)), digit: '' };
  } else if (number / 1e3 >= 10) {
    return { number: Number((number / 1e3).toFixed(0)), digit: 'k' };
  } else return { number: Number(number), digit: '' };
};
